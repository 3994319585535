(function ($) {
  Drupal.behaviors.toutCarouselFormatterV1 = {
    attach: function (context) {
      var $formatter = $('.js-tout-carousel-formatter-v1', context);
      var $grids = $('.js-tout-carousel-formatter__grid', $formatter);
      var $carouselGrids = $grids.filter('.js-tout-carousel-formatter__grid--items');
      var $carousels = $('.js-tout-carousel-formatter_items', $carouselGrids);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $carousels.each(function () {
        const $parent = $(this).parent();
        var $prevArrow = $parent.find('.js-slick-prev-button');
        var $nextArrow = $parent.find('.js-slick-next-button');

        var settings = {
          prevArrow: $prevArrow,
          nextArrow: $nextArrow,
          arrows: true,
          dots: false,
          infinite: true,
          slidesToShow: $(this).data('slides-landscape'),
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: $(this).data('slides-medium'),
                slidesToScroll: 1
              }
            }
          ]
        };

        // Init this carousel with our settings
        $(this).slick(settings);
      });
    }
  };
})(jQuery);
